<template>
  <div class="contailPanelV">
   <page-list target="_self"
              @pageChanged="handlePageChange"
              :detailPath="detailPath"
              :list="newlist"
              :current="pagination.current" 
              :size="pagination.size"
              :total="total"
           />
  </div>
</template>

<script>

import pageList from "@/components/list/pageList"; 
export default {
  name: "newsList", //信息公告页面
  components: {
    pageList, //引入内容组件
  },
       
  watch:{
    $route:{
      handler(val){
        // console.log("----------- route path changed  oldval", oldval)
        this.newsTag = val.params.newsTag
        this.getlist()
      },
      // 深度观察监听
      deep: true
    }
  }, 

  data() {
    return {
      newsTag: '0',
      detailPath: '/news/detail',
      newlist: [], //当前数组  
      total: 0, //总数
      pagination: {
        current: 1, //当前页码
        size: 20, //每页条数
      },
    }
  },
  created(){
    this.init()
    this.getlist()
  },
  methods: {
    init(){
      this.newsTag = this.$route.params.newsTag
    },
    resetPagination(){
      this.pagination = {
        // current: 1, 
        // size:  this.pagination.size
      }
    },

    handlePageChange(e) {
      // console.log(" page changed  ",  e)
      this.pagination = e;
      this.getlist();
    },
    // 获取当前数组
    getlist() {
      var data = {
        newsTag: this.newsTag,
        status: 1,
        ...this.pagination,
      };
      // console.log(" get list query ", data)
      this.$api.getNewsList(data).then((res) => {
        // console.log(" get list result ", res)
        this.newlist = res.data.records;
        this.total = res.data.total;
        this.istrue=true
        // console.log("   after get news ", this.pagination )
      });
    },
  },
};
</script>

<style scoped lang="less">
// @media screen and (min-width: 1000px) {
//   .detailbox {
//       min-height: 50vh;
//     // 面包屑
//     /deep/ .more {
//       display: none;
//     }
//     /deep/ .cardbox {
//       padding: 0;
//       .cardhead {
//         padding-left: 0.375rem;
//       }
//       .cardcent {
//         margin-top: 1rem;
//         padding-left: 0.375rem;

//       }
//     }
//   }
// }

// @media screen and (max-width: 999px) {
//   .detailbox {
//       min-height: 50vh;
//     // 面包屑
//     /deep/ .more {
//       display: none;
//     }
//     /deep/ .cardbox {
//       padding: 0 .9375rem;
//       .cardhead {
//         padding-left:.675rem;
//       }
//       .cardcent {
//         .hangbox {
//           height: 1.8rem;
//           margin: 0;
//         }
//       }
//     }
//   }
// }
</style>

